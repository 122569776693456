//ICONS
import AddAlert from "@material-ui/icons/AddAlert"
import AssessmentIcon from "@material-ui/icons/Assessment"
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd"
//import HomeIcon from "@material-ui/icons/Home"
import DashboardIcon from "@material-ui/icons/Dashboard"
import EventIcon from "@material-ui/icons/Event"
import FolderOpen from "@material-ui/icons/FolderOpen"
import HelpIcon from "@material-ui/icons/Help"
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn"
import NotificationsNone from "@material-ui/icons/NotificationsNone"
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle"
import AddProcesses from "containers/addProcess/AddProcesses"
import Agenda from "containers/agenda/Agenda"
import VSNotificationDetail from "containers/clients/VsNotificationDetail/VSNotificationDetail"
import Home from "containers/home/Home"
import MoneyManagement from "containers/moneyManagement/moneyManagement/MoneyManagement"
import NewMoneyManagement from "containers/moneyManagement/newMoneyManagement/NewMoneyManagement"
import Notifications from "containers/notifications/Notifications"
import Processes from "containers/processes/Processes"
import DetailReport from "containers/reports/detailReports/DetailReport"
import Reports from "containers/reports/reports/Reports"
import Search from "containers/search/Search"
import CreateSubaccout from "containers/subaccounts/createSubaccount/CreateSubaccount"
import { DetailSubaccount } from "containers/subaccounts/detailSubaccount/DetailSubaccount"
import Subaccount from "containers/subaccounts/subaccounts/Subaccounts"
import SubaccountsProcesses from "containers/subaccounts/subaccountsProcesses/SubaccountsProcesses"
import UpdateSubaccout from "containers/subaccounts/updateSubaccount/UpdateSubaccount"
import SummaryProcess from "containers/sumaryProcess/SummaryProcess"
import UpdateProcess from "containers/updateProcess/UpdateProcess"
import Building from "containers/views/Building"
import VSNotifications from "../containers/clients/VSNotifications/VSNotifications"

export const Paths = {
	home: "/",
	notifications: "/notificaciones",
	processes: "/processes",
	get newProcess() {
		return `${this.processes}/addprocess`
	},
	get summaryProcess() {
		return `${this.processes}/:id/summary`
	},
	get updateProcess() {
		return `${this.processes}/:id/update`
	},
	subacounts: "/subcuentas",
	get detailSubaccout() {
		return `${this.subacounts}/processes/:subaccountID`
	},
	get createSubaccout() {
		return `${this.subacounts}/create`
	},
	get updateSubaccout() {
		return `${this.subacounts}/update/:id`
	},
	get subaccountsProcesses() {
		return `${this.subacounts}/processes`
	},
	moneyManagement: "/gestiondecaja",
	get newMoneyManagement() {
		return `${this.moneyManagement}/new`
	},
	clients: "/clientes",
	get VSNotifications() {
		return `${this.clients}/vs-notifications`
	},
	get VSNotificationDetail() {
		return `${this.VSNotifications}/:id`
	},
	agenda: "/agenda",
	reports: "/informes",
	get detailReport() {
		return `${this.reports}/:id/detail`
	},
	help: "/ayuda",
	logout: "/logout",
	general: "/generales",
	search: "/buscar",
}

export const Routes = {
	home: {
		label: "Inicio",
		path: Paths.home,
		component: Home,
		icon: DashboardIcon,
	},
	search: {
		label: "Buscar",
		path: Paths.search,
		component: Search,
		icon: AddAlert,
	},
	processes: {
		label: "Procesos",
		path: Paths.processes,
		component: Processes,
		icon: FolderOpen,
		children: {
			newProcess: {
				label: "Nuevo Proceso",
				path: Paths.newProcess,
				component: AddProcesses,
				visible: true,
			},
			summaryProcess: {
				label: "Hoja de vida del proceso",
				path: Paths.summaryProcess,
				component: SummaryProcess,
				visible: false,
			},
			updateProcess: {
				label: "Actualizar proceso",
				path: Paths.updateProcess,
				component: UpdateProcess,
				visible: false,
			},
		},
	},
	notifications: {
		label: "Notificaciones",
		path: Paths.notifications,
		component: Notifications,
		icon: NotificationsNone,
	},
	agenda: {
		label: "Agenda",
		path: Paths.agenda,
		component: Agenda,
		icon: EventIcon,
	},
	subCounts: {
		label: "Subcuentas",
		path: Paths.subacounts,
		component: Subaccount,
		icon: SupervisedUserCircleIcon,
		children: {
			createSubaccout: {
				label: "Crear subcuenta",
				path: Paths.createSubaccout,
				component: CreateSubaccout,
				visible: true,
			},
			updateSubaccout: {
				label: "Actualizar subcuenta",
				path: Paths.updateSubaccout,
				component: UpdateSubaccout,
				visible: false,
			},
			subaccountsProcesses: {
				label: "Asignar procesos",
				path: Paths.subaccountsProcesses,
				component: SubaccountsProcesses,
				visible: true,
			},
			detailSubaccout: {
				label: "Detalle subcuenta",
				path: Paths.detailSubaccout,
				component: DetailSubaccount,
				visible: false,
			},
		},
	},
	moneyManagement: {
		label: "Manejo de caja",
		path: Paths.moneyManagement,
		component: MoneyManagement,
		icon: MonetizationOnIcon,
		children: {
			newMoneyManagement: {
				label: "Nuevo",
				path: Paths.newMoneyManagement,
				component: NewMoneyManagement,
				visible: true,
			},
		},
	},
	clients: {
		label: "C.R.M",
		path: Paths.clients,
		component: Building,
		icon: AssignmentIndIcon,
		children: {
			VSNotifications: {
				label: "VS Notificaciones",
				path: Paths.VSNotifications,
				component: VSNotifications,
				visible: true,
			},
			VSNotificationDetail: {
				label: "Detalle",
				path: Paths.VSNotificationDetail,
				component: VSNotificationDetail,
				visible: false,
			},
		},
	},
	reports: {
		label: "Informes",
		path: Paths.reports,
		component: Reports,
		icon: AssessmentIcon,
		children: {
			detailReports: {
				label: "Informe Específico",
				path: Paths.detailReport,
				component: DetailReport,
				visible: true,
			},
		},
	},
	help: {
		label: "Ayuda",
		path: Paths.help,
		component: Building,
		icon: HelpIcon,
	},
}
