import { createTheme, ThemeProvider } from "@material-ui/core/styles"
import {
	dark,
	darkGray,
	gray,
	light,
	lightGray,
	primary,
	primaryDark,
	ultraLightGray,
} from "./colors"

export const ConfigBasicTheme = createTheme({
	palette: {
		primary: {
			main: primary,
			dark: primaryDark,
			contrastText: light,
		},
		secondary: {
			main: primary,
			contrastText: light,
		},
		gray,
		light,
		darkGray,
		lightGray,
		dark,
		ultraLightGray,
	},
	typography: {
		h1: 32,
		h2: 23,
		h3: 20,
		h4: 17,
		h5: 15,
		h6: 13,
		button: {
			textTransform: "none",
		},
	},
	overrides: {
		MuiTable: {
			backgroundColor: "red",
			height: 200,
		},
		MuiTableCell: {
			root: {
				color: `${darkGray} !important`,
				fontWeight: 500,
				fontSize: 13,
			},
		},
		MuiTableBody: {
			root: {
				cursor: "pointer",
			},
		},
		MuiTableHead: {
			root: {
				"& .MuiTableCell-root": {
					fontWeight: 600,
					fontSize: 13,
				},
			},
		},
		MuiTypography: {
			body1: {
				fontSize: ".88rem",
				lineHeight: "1",
				"& strong": {
					fontWeight: "700 !important",
				},
			},
		},
		MuiButton: {
			label: {
				fontSize: ".8rem",
				fontWeight: "600",
			},
		},
		MuiIconButton: {
			root: {
				padding: 8,
			},
		},
		MuiSvgIcon: {
			root: {
				fontSize: "1.4rem",
			},
		},
		MuiCard: {
			root: {
				boxShadow: "0px 8px 8px 0px #00000014 !important",
			},
		},
	},
})

const BasicTheme = ({ children }) => (
	<ThemeProvider theme={ConfigBasicTheme}>{children}</ThemeProvider>
)

export default BasicTheme
