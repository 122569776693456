import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@material-ui/core"
import { Button, Input } from "common/components/inputs/basics"
import { useMainContext } from "common/context/mainContext/mainContext"
import useCreateNotification from "common/hooks/useCreateNotification"
import { useFormik } from "formik"
import moment from "moment"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import * as Yup from "yup"

export const CreateUpdateNotifications = ({
	id,
	actuacion,
	fecha_creacion,
	onClose,
}) => {
	const { id: processID } = useParams()
	const { createNotifications } = useCreateNotification()
	const { getProcessHistory } = useMainContext().process
	const { updateNotification } = useMainContext().notifications

	const validationSchema = Yup.object().shape({
		actuacion: Yup.string().required("Requerido"),
	})

	const { values, handleChange, touched, errors, handleSubmit } =
		useFormik({
			validationSchema,
			initialValues: {
				actuacion,
				fecha_creacion,
			},
			onSubmit: async (values) => {
				values.fecha_creacion = moment(values.fecha_creacion)
				values.cup = processID
				if (id) await updateNotification(id, values)
				else await createNotifications(values, processID)
				getProcessHistory(processID)
				onClose(false)
			},
		})

	return (
		<Dialog
			open
			fullWidth
			maxWidth="xs"
			onClose={() => {
				onClose(false)
			}}
		>
			<DialogTitle>
				{!id && "Agregar Gestion"}
				{id && "Editar Gestion"}
			</DialogTitle>
			<form noValidate autoComplete="off" onSubmit={handleSubmit}>
				<DialogContent>
					<Box mb={2}>
						<Input
							type="date"
							name="fecha_creacion"
							label="Fecha"
							onChange={handleChange}
							value={values.fecha_creacion}
						/>
					</Box>
					<Box mb={3}>
						<Input
							rows={3}
							multiline
							name="actuacion"
							label="Actividad"
							onChange={handleChange}
							value={values.actuacion}
							errorText={errors.actuacion}
							error={touched.actuacion && !!errors.actuacion}
						/>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => onClose(false)}>Cancelar</Button>
					<Button color="primary" type="submit">
						Confirmar
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	)
}
