import {
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Typography,
} from "@material-ui/core"
import AlertMessage from "common/components/AlertMessage"
import { Input } from "common/components/inputs/basics"
import request from "common/plugins/request"
import { useFormik } from "formik"
import { useState } from "react"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import * as Yup from "yup"

const EmailValidationForm = ({ isOpen, onChangeOpen }) => {
	const history = useHistory()
	const [userIsCreated, setUserIsCreated] = useState(false)

	const emailForm = useFormik({
		initialValues: {
			code: "",
		},
		validationSchema: Yup.object().shape({
			code: Yup.string()
				.required("Requerido")
				.test(
					"invalidCode",
					"El codigo ingresado no es valido",
					async (codigo) => {
						try {
							const { results } = await request.get(
								`/codigoverificacioncorreo/?codigo=${codigo}`
							)
							return !!results?.length
							// setOpenValidationCode(true)
						} catch (e) {
							console.error(e)
							return false
						}
					}
				),
		}),
		onSubmit: async ({ code: codigo }) => {
			setUserIsCreated(true)
			setTimeout(() => {
				onChangeOpen(false)
				history.push("/")
			}, 2000)
		},
	})

	return (
		<>
			<AlertMessage
				open={userIsCreated}
				type="success"
				title="Usuario creado"
				message="El usuario se ha creado exitosamente"
			/>
			<Dialog open={isOpen} maxWidth="xs">
				<form
					noValidate
					autoComplete="off"
					onSubmit={emailForm.handleSubmit}
				>
					<DialogTitle
						style={{ textAlign: "center", color: "#5b5a5d" }}
					>
						Validando correo ingresado
					</DialogTitle>
					<DialogContent>
						<Typography component="p" style={{ lineHeight: "1.4" }}>
							Ingresa el código que llegó a{" "}
							<span style={{ color: "#129BDB" }}>
								{emailForm.values.email}
							</span>{" "}
							para poder activar tu usuario y disfrutar del periodo
							gratuito.
						</Typography>

						<Box my={3} display="flex" justifyContent="space-between">
							<Input
								name="code"
								label="Codigo"
								style={{ maxWidth: 230 }}
								onChange={emailForm.handleChange}
								value={emailForm.values.code}
								errorText={emailForm.errors.code}
								error={
									emailForm.touched.code && !!emailForm.errors.code
								}
							/>
							<Button
								type="submit"
								variant="contained"
								color="primary"
							>
								Verificar codigo
							</Button>
						</Box>
					</DialogContent>
				</form>
			</Dialog>
		</>
	)
}

export default EmailValidationForm
