import { Box, IconButton } from "@material-ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import DescriptionIcon from "@material-ui/icons/Description"
import EditIcon from "@material-ui/icons/Edit"
import { DataGrid } from "common/components/DataGrid"
import { Button } from "common/components/inputs/basics"
import { useMainContext } from "common/context/mainContext/mainContext"
import { UcFirst } from "containers/signUp/utils/UcFirst"
import moment from "moment"
import { useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import CardProcessData from "./cardProcessData"
import { CreateUpdateNotifications } from "./CreateUpdateNotifications"

const ProcessData = () => {
	const { id: processID } = useParams()
	const { getProcess, getProcessHistory, process, processHistory } =
		useMainContext().process
	const { deleteNotification } = useMainContext().notifications

	const [openGestion, setOpenGestion] = useState()
	const [activeNotification, setActiveNotification] = useState()

	const onDeleteNotification = (id) => {
		deleteNotification(id)
		getProcessHistory(processID)
	}

	const onUpdateNotification = (notification) => {
		setActiveNotification({
			id: notification.id,
			fecha_creacion: moment(notification.fecha_creacion).format(
				"YYYY-MM-DD"
			),
			actuacion: notification.actuacion,
		})
		setOpenGestion(true)
	}

	useEffect(() => {
		getProcess(processID)
		getProcessHistory(processID)
		// eslint-disable-next-line
	}, [])

	const columns = useMemo(
		() => [
			{
				size: 50,
				accessorKey: "created_at",
				header: "Fecha",
				muiTableBodyCellProps: {
					sx: { verticalAlign: "top" },
				},
				Cell: ({ row: { original: notification } }) => (
					<Box display="flex" justifyContent="center">
						{notification.tipo_de_notificacion === "GESTION"
							? moment(notification.fecha_creacion).format(
									"YYYY-MM-DD"
							  )
							: moment(notification.created_at).format("YYYY-MM-DD")}
					</Box>
				),
			},
			{
				size: 120,
				accessorKey: "office",
				header: "Autor",
				muiTableBodyCellProps: {
					sx: { verticalAlign: "top" },
				},
				Cell: ({ renderedCellValue: office }) => UcFirst(office),
			},
			{
				size: 80,
				accessorKey: "tipo_de_notificacion",
				header: "Tipo de Actuación",
				muiTableBodyCellProps: {
					sx: { verticalAlign: "top" },
				},
				Cell: ({ renderedCellValue: tipo_de_notificacion }) => (
					<Box style={{ textAlign: "center" }}>
						{tipo_de_notificacion === "GESTION"
							? "Gestión"
							: "Notificación"}
					</Box>
				),
			},
			{
				size: 300,
				accessorKey: "actuacion",
				header: "Descripción",
				Cell: ({ renderedCellValue: actuacion }) =>
					UcFirst(actuacion),
			},
			{
				maxSize: 100,
				accessorKey: "id",
				header: "Acción",
				Cell: ({
					renderedCellValue: id,
					row: { original: notification },
				}) => (
					<Box display="flex" justifyContent="center">
						{!!notification.url?.trim()?.length && (
							<IconButton
								onClick={() => window.open(notification.url)}
							>
								<DescriptionIcon style={{ fontSize: "1.2rem" }} />
							</IconButton>
						)}
						{notification.tipo_de_notificacion === "GESTION" && (
							<>
								<IconButton
									onClick={() => onUpdateNotification(notification)}
								>
									<EditIcon style={{ fontSize: "1.3rem" }} />
								</IconButton>
								<IconButton onClick={() => onDeleteNotification(id)}>
									<DeleteIcon style={{ fontSize: "1.3rem" }} />
								</IconButton>
							</>
						)}
					</Box>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[processHistory]
	)

	if (!Object.values(process).length) return null

	return (
		<>
			{openGestion && (
				<CreateUpdateNotifications
					{...activeNotification}
					onClose={() => {
						setOpenGestion(false)
						setActiveNotification({})
					}}
				/>
			)}
			<CardProcessData process={process} />
			<Box mt={4} mb={2} display="flex" justifyContent="flex-end">
				<Button color="primary" onClick={() => setOpenGestion(true)}>
					Crear Gestion
				</Button>
			</Box>

			<DataGrid
				columns={columns}
				data={processHistory}
				enablePagination={false}
			/>
		</>
	)
}

export default ProcessData
